import { BookingService } from "src/helpers/autoservice-account-backend/types"
import {
  GarageAdditionalService,
  GarageService,
} from "src/states/serviceFlow/garageActions"
import { ServiceCategory } from "src/states/serviceFlow/types"

import { AdditionalService } from "./autoservice-core-backend/types"

const getAdditionalServicePrice = (
  garageAditionalService?: GarageAdditionalService,
  additionalService?: AdditionalService,
) => {
  // to show the free additional service label
  if (additionalService?.price || additionalService?.price === 0) {
    return {
      priceBeforeDiscount: additionalService.price,
      priceAfterDiscount: additionalService.price,
      discountAmount: 0,
    }
  }

  if (!!garageAditionalService?.priceAfterDiscount) {
    return {
      priceBeforeDiscount: garageAditionalService.priceBeforeDiscount,
      priceAfterDiscount: garageAditionalService.priceAfterDiscount,
      discountAmount: garageAditionalService.discountAmount,
    }
  }
  return
}

export const getServiceDetailsWithPrice = (
  garageServices: GarageService[] | undefined,
  serviceCategories: ServiceCategory[] | undefined,
): BookingService[] | undefined => {
  const bookingServices: BookingService[] | undefined = serviceCategories?.map(
    (serviceCategory) => {
      // Find which garage services is related with the chosen selected service
      const garageService = garageServices?.find(
        (service) =>
          service.internalServiceId ===
          serviceCategory.service?.internalServiceId,
      )

      const additionalServices = serviceCategory.additionalServices.map(
        (additionalService) => {
          const garageAditionalService = garageService?.additionalServices.find(
            (item) =>
              item.additionalServiceId ===
              additionalService.internalAdditionalServiceId,
          )

          return {
            id:
              garageAditionalService?.additionalServiceId ??
              additionalService.internalAdditionalServiceId ??
              "",
            ...getAdditionalServicePrice(
              garageAditionalService,
              additionalService,
            ),
            name: additionalService.title ?? "",
          }
        },
      )

      // Build the final object and return
      const bookingService: BookingService = {
        id:
          garageService?.internalServiceId ??
          serviceCategory?.service?.internalServiceId ??
          "",
        categoryId: serviceCategory.internalServiceCategoryId,
        name: serviceCategory?.service?.title ?? serviceCategory?.title ?? "",
        icon: serviceCategory?.icon ?? "service",
        priceBeforeDiscount: !!garageService?.priceBeforeDiscount
          ? garageService?.priceBeforeDiscount
          : undefined,
        priceAfterDiscount: !!garageService?.priceAfterDiscount
          ? garageService?.priceAfterDiscount
          : undefined,
        discountAmount: !!garageService?.discountAmount
          ? garageService?.discountAmount
          : undefined,
        additionalServices,
        packageAdditionalServiceIds:
          serviceCategory.service?.packageAdditionalServiceIds,
      }

      return bookingService
    },
  )

  return bookingServices
}
