import { ROUTES } from "src/config"
import { Category } from "src/helpers/autoservice-core-backend/types"
import { getServiceNameById } from "src/helpers/getServiceNameById"
import { useTranslation } from "src/hooks/translation"
import { useServiceFlowData } from "src/states/serviceFlow"

export const useMostSelectedServicesChips = (categoriesData?: Category[]) => {
  const { messages } = useTranslation()
  const translation = messages.components.core.mostBookedServices

  const recommendedCategories: Category["internalServiceCategoryId"][] = [
    "HU_AU",
    "WHEEL_CHANGE",
    "OIL_CHANGE",
  ]

  const { serviceCategories } = useServiceFlowData()

  // BRAKE_PAD_CHANGE and BRAKE_DISC_CHANGE have the same route
  const selectedCategoryIds = serviceCategories?.map(
    ({ internalServiceCategoryId }) =>
      internalServiceCategoryId === "BRAKE_PAD_CHANGE"
        ? "BRAKE_DISC_CHANGE"
        : internalServiceCategoryId,
  )

  if (!categoriesData) return undefined

  const serviceChips = categoriesData
    .filter(
      (category) =>
        recommendedCategories.includes(category.internalServiceCategoryId) &&
        !selectedCategoryIds?.includes(category.internalServiceCategoryId) &&
        !category.isHiddenInList,
    )
    .map((category) => {
      const { title, internalServiceCategoryId } = category

      const serviceName = getServiceNameById(internalServiceCategoryId)

      return {
        href: ROUTES.service({ serviceName }),
        title: title || "",
        id: internalServiceCategoryId || "",
        "data-cy": `most_booked_${internalServiceCategoryId}`,
      }
    })

  serviceChips.push({
    href: ROUTES.serviceFlow({}),
    title: translation.all,
    id: "",
    "data-cy": "most_booked_view_all",
  })

  return serviceChips
}
