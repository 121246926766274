import { useMediaQuery } from "react-responsive"

import { ReactComponent as LogoIcon } from "src/assets/logos/autowelt.svg"
import { BaseLink } from "src/components/common/base-link"
import { ROUTES } from "src/config"
import { screenBreakpoints } from "src/const/screenBreakpoints"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"
import { useFlow } from "src/hooks/useFlow"

import styles from "./Logo.module.scss"

const bem = create(styles, "Logo")

export const Logo = () => {
  const { messages } = useTranslation()
  const { header } = messages.components.core

  const { isIframeBookingFlow } = useFlow()
  const isDesktop = useMediaQuery({
    minWidth: screenBreakpoints.lg,
  })

  if (isIframeBookingFlow) {
    if (isDesktop) {
      return (
        <div className={bem()}>
          <LogoIcon
            className={bem("icon")}
            aria-label={header.logo}
            aria-hidden="true"
          />
        </div>
      )
    }

    // Needs to return an empty div because of flexbox
    return <div />
  }

  return (
    <BaseLink href={ROUTES.index} className={bem()}>
      <LogoIcon
        className={bem("icon")}
        aria-label={header.logo}
        aria-hidden="true"
      />
      <span className={bem("label")}>{header.logo}</span>
    </BaseLink>
  )
}
