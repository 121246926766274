import { VehicleData } from "src/states/serviceFlow/vehicleActions"

export const hasDefaultVehicleData = (vehicle?: VehicleData) => {
  const {
    hsn,
    tsn,
    engineId,
    licensePlateInternational,
    licensePlateLetters,
    licensePlateNumber,
    licensePlateRegion,
    isManualHsnTsn,
  } = vehicle || {}

  const hasHsnTsn = isManualHsnTsn ? true : !!hsn && !!tsn
  const hasEngineId = !!engineId
  const hasLicensePlate =
    !!licensePlateInternational ||
    (!!licensePlateLetters && !!licensePlateNumber && !!licensePlateRegion)

  return (hasHsnTsn || hasEngineId) && hasLicensePlate
}
