import { StorageOptions } from "../syncToPersistentStorage"
import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

import { AccountData } from "./types"

const localStorage: StorageOptions = {
  key: "account",
  version: "2023-12-11",
  storage: "localStorage",
}

const { getValue, setValue, useValue, mergeValue } =
  createSimpleGlobalState<AccountData>({}, localStorage)

export {
  getValue as getAccountData,
  setValue as setAccountData,
  useValue as useAccountData,
  mergeValue as mergeAccountData,
}
