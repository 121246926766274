import { FC } from "react"

import { ServicesSection } from "../../bookingSummaryCard/sections/servicesSection"
import { ServicePriceCalculationComponents } from "src/components/pages/services/checkout/serviceSummary/BookingSummary"

import { getServiceDetailsWithPrice } from "src/helpers/getServiceDetailsWithPrice"
import { useServiceFlowData } from "src/states/serviceFlow"

type ServicesListProps = {
  onClose?: () => void
}
export const ServicesList: FC<ServicesListProps> = ({ onClose }) => {
  const { serviceCategories, detailedGarage, servicePrices } =
    useServiceFlowData()
  const services = getServiceDetailsWithPrice(
    detailedGarage?.services,
    serviceCategories,
  )

  const servicePriceCalculationComponents: ServicePriceCalculationComponents =
    servicePrices?.map((services) => {
      return {
        priceCalculationComponents: services.priceCalculationComponents,
        id: services.internalServiceId,
      }
    })

  return (
    <ServicesSection
      onClose={onClose}
      services={services}
      garage={detailedGarage?.garage}
      servicePriceCalculationComponents={servicePriceCalculationComponents}
      isShoppingCart
    />
  )
}
