import { FlowStep, serviceFlowSteps } from "src/config"

// stepper for default flow
export const defaultFlowSteps = [
  serviceFlowSteps.garages,
  serviceFlowSteps.vehicle,
  serviceFlowSteps.checkout,
] as FlowStep[]

// stepper for pitstop inspection request flow
export const pitstopInspectionFlowSteps = [
  serviceFlowSteps.garages,
  serviceFlowSteps.vehicle,
  serviceFlowSteps.checkout,
] as FlowStep[]
