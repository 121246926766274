import { useRouter } from "next/router"
import React, { FC } from "react"

import { Chip, Chips } from "src/components/common/chips"

import { Icon } from "src/components/common/icon"
import { Category } from "src/helpers/autoservice-core-backend/types"
import { create } from "src/helpers/bem"

import { useTranslation } from "src/hooks/translation"

import { useMostSelectedServicesChips } from "./hooks/useMostSelectedServicesChips"
import styles from "./MostBookedServices.module.scss"

const bem = create(styles, "MostBookedServices")

type Service = {
  href: string
  id: string
  title: string
  categoryId?: string
}
type ServiceChip = Chip<Service>

type MostBookedServicesProps = {
  onSelect?: (name: string) => void
  categoriesData?: Category[]
}

/**
 * Returns a title and a Chip list of most booked services
 */
export const MostBookedServices: FC<MostBookedServicesProps> = ({
  onSelect,
  categoriesData,
}) => {
  const { messages } = useTranslation()
  const router = useRouter()

  const serviceChips: ServiceChip[] | undefined =
    useMostSelectedServicesChips(categoriesData)

  if (!serviceChips?.length) {
    return null
  }

  const handleClick = (item: ServiceChip) => {
    onSelect?.(item.title)

    router.push(item.href)
  }

  return (
    <div className={bem()}>
      <div className={bem("title-container")}>
        <Icon className={bem("icon")} name="wrench" />
        <h3 className={bem("title")}>
          {messages.components.core.mostBookedServices.title}
        </h3>
      </div>

      <Chips items={serviceChips} onClick={handleClick} variant="primary" />
    </div>
  )
}
