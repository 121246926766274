import {
  Root,
  PopoverContentProps,
  Trigger,
  Portal,
  Content,
} from "@radix-ui/react-popover"
import { FC, PropsWithChildren, ReactNode } from "react"

import { create } from "src/helpers/bem"

import styles from "./Popover.module.scss"

const bem = create(styles, "Popover")

export type PopoverProps = PropsWithChildren<
  PopoverContentProps & {
    open?: boolean
    trigger?: ReactNode
    onOpenChange?: (open: boolean) => void
  }
>

export const Popover: FC<PopoverProps> = ({
  open,
  trigger,
  onOpenChange,
  ...props
}) => {
  return (
    <Root open={open} onOpenChange={onOpenChange}>
      <Trigger asChild>{trigger}</Trigger>
      <Portal>
        <Content className={bem()} {...props} />
      </Portal>
    </Root>
  )
}
