import {
  defaultFlowSteps,
  pitstopInspectionFlowSteps,
} from "../const/flowSteps"
import { FlowStep } from "src/config"
import { useInspectionFlows } from "src/hooks/useInspectionFlows"

export const useGetFlowSteps = () => {
  const { isInspectionOfferFlow, isPitstopInspectionFlow } =
    useInspectionFlows()

  let steps: FlowStep[] = getInitialSteps(
    isInspectionOfferFlow,
    isPitstopInspectionFlow,
  )

  return steps
}

function getInitialSteps(
  isInspectionOfferFlow: boolean,
  isPitstopInspectionFlow: boolean,
) {
  if (isInspectionOfferFlow) {
    return []
  }

  if (isPitstopInspectionFlow) {
    return pitstopInspectionFlowSteps
  }

  return defaultFlowSteps
}
