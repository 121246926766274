import { FlowStep } from "src/config"
import { useTranslation } from "src/hooks/translation"

export const useBackButtonTitle = (step?: FlowStep) => {
  const { messages } = useTranslation()
  const translations = messages.pages.services.flow.buttons.back

  const stepsTitles: Partial<Record<FlowStep, string>> = {
    index: translations.services,
    fahrzeug: translations.vehicle,
    werkstaetten: translations.garage,
  }

  if (!step || !stepsTitles[step]) {
    return translations.default
  }

  return stepsTitles[step]
}
