import { FlowStep, serviceFlowSteps } from "src/config"
import { isPitstopGarage } from "src/helpers/isPitstopGarage"
import { useInspectionFlows } from "src/hooks/useInspectionFlows"
import { useServiceFlowData } from "src/states/serviceFlow"

import { hasDefaultVehicleData } from "./helpers/hasDefaultVehicleData"
import { useGetFlowSteps } from "./useGetFlowSteps"

export const useFlowStep = (currentFlowStep?: FlowStep) => {
  const { serviceCategories, inquiry, detailedGarage, vehicle, timeSlot } =
    useServiceFlowData()
  const {
    isInspectionCategorySelected,
    isPitstopInspectionFlow,
    isInspectionOfferFlow,
  } = useInspectionFlows()

  const flowSteps = useGetFlowSteps()

  const stepsConditions: Partial<
    Record<FlowStep, { enter: () => boolean; leave: () => boolean }>
  > = {
    index: {
      enter: () => true,
      leave: () => {
        return getIsCategoryOrInquirySelected()
      },
    },
    fahrzeug: {
      enter: () => true,
      leave: () => {
        return getIsVehicleDataFilled()
      },
    },
    werkstaetten: {
      enter: () => getIsCategoryOrInquirySelected(),
      leave: () => {
        return (
          getIsGarageSelected() &&
          getIsAppointmentOrInspectionSelected() &&
          !checkIfNotBookable()
        )
      },
    },
    "persoenliche-daten": {
      enter: () => {
        if (isInspectionOfferFlow) {
          return true
        }

        return (
          getIsCategoryOrInquirySelected() &&
          getIsVehicleDataFilled() &&
          getIsGarageSelected()
        )
      },
      leave: () => {
        return true
      },
    },
  }

  const prevFlowStep = getPrevFlowStep()
  const nextFlowStep = getNextFlowStep()
  const isCurrentStepAvailable = getIsStepAvailable()

  function checkIfNotBookable() {
    const isPitstop = isPitstopGarage(detailedGarage?.garage?.dmsProviderType)

    return (
      isPitstop &&
      isInspectionCategorySelected &&
      serviceCategories &&
      serviceCategories.length > 1
    )
  }

  function getIsCategoryOrInquirySelected() {
    return !!serviceCategories?.length || !!inquiry?.message
  }

  function getIsGarageSelected() {
    return !!detailedGarage?.garage?.id
  }

  function getIsVehicleDataFilled() {
    if (!hasDefaultVehicleData(vehicle)) return false

    const { kmDriven, gearType, dateFirstRegistered, vin } = vehicle || {}
    // Tests inspection-specific params
    if (isInspectionCategorySelected) {
      return !!kmDriven && !!gearType && !!dateFirstRegistered && !!vin
    }

    return true
  }

  function getIsAppointmentOrInspectionSelected() {
    return !!timeSlot || isPitstopInspectionFlow
  }

  function getIsStepCompleted(step: FlowStep | undefined) {
    return !!step && stepsConditions[step]?.leave()
  }

  function getNextFlowStep() {
    let index = 0
    const lastStepIndex = flowSteps.length - 1

    while (
      (getIsStepCompleted(flowSteps[index]) ||
        currentFlowStep === flowSteps[index]) &&
      index !== lastStepIndex
    ) {
      index = index + 1
    }

    return flowSteps[index]
  }

  function getPrevFlowStep(step: FlowStep | undefined = currentFlowStep) {
    if (!step) {
      return
    }

    const index = flowSteps.indexOf(step)

    return flowSteps[index - 1] || serviceFlowSteps.service
  }

  function getIsStepAvailable(step: FlowStep | undefined = currentFlowStep) {
    if (!step) {
      return false
    }

    return stepsConditions[step]?.enter()
  }

  return {
    nextFlowStep,
    prevFlowStep,
    getIsStepCompleted,
    isCurrentStepAvailable,
    flowSteps,
  }
}
