import { FC } from "react"

import { Button } from "src/components/common/button"
import { Icon } from "src/components/common/icon"

import { useShoppingCartTrackingEvents } from "src/components/core/shoppingCart/hooks/useShoppingCartTrackingEvents"
import { ROUTES } from "src/config"
import { create } from "src/helpers/bem"

import { useTranslation } from "src/hooks/translation"

import styles from "./FAQSection.module.scss"

const bem = create(styles, "FAQSection")

export const FAQSection: FC = () => {
  const { messages } = useTranslation()
  const translations = messages.components.core.shoppingCart.faq

  const { trackFAQClick } = useShoppingCartTrackingEvents()

  return (
    <div className={bem()}>
      <div className={bem("title-container")}>
        <Icon className={bem("icon")} name="questionMark" />
        <p className={bem("title")}>{translations.title}</p>
      </div>
      <Button
        href={ROUTES.qa}
        target="_blank"
        variant="tertiary"
        onClick={trackFAQClick}
        icon={{
          position: "right",
          name: "externalLink",
        }}
        className={bem("link")}
      >
        {translations.link}
      </Button>
    </div>
  )
}
