import { signOut } from "next-auth/react"
import { useEffect } from "react"

import { signInWithOAuth } from "src/helpers/next-auth/client"
import { AuthData } from "src/helpers/next-auth/types"

export const useKeycloakAuthError = (auth?: AuthData) => {
  useEffect(() => {
    if (auth?.error === "RefreshAccessTokenError") {
      signInWithOAuth() // Force sign in to hopefully resolve error
    } else if (auth?.error === "FailedFetchingUser") {
      signOut()
    }
  }, [auth?.error])
}
