import { FC } from "react"

import { useShoppingCartTrackingEvents } from "../hooks/useShoppingCartTrackingEvents"
import { Button } from "src/components/common/button"
import { Icon } from "src/components/common/icon"

import { servicesBaseRoute } from "src/config"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import styles from "./EmptyShoppingCart.module.scss"

type EmptyShoppingCartProps = {
  onClose: () => void
}

const bem = create(styles, "EmptyShoppingCart")

export const EmptyShoppingCart: FC<EmptyShoppingCartProps> = ({ onClose }) => {
  const { messages } = useTranslation()
  const translations = messages.components.core.shoppingCart.empty

  const { trackGoToServiceSelection } = useShoppingCartTrackingEvents()

  const onClickServiceSelection = () => {
    trackGoToServiceSelection()
    onClose()
  }

  return (
    <div className={bem()} data-cy="shopping-cart-empty">
      <Icon className={bem("icon")} name="emptyShoppingCart" />
      <p className={bem("text")}>{translations.description}</p>
      <div className={bem("buttons")}>
        <Button
          href={`/${servicesBaseRoute}`}
          className={bem("link")}
          variant="primary"
          onClick={onClickServiceSelection}
          data-cy="shopping-cart-booking-button"
        >
          {translations.buttons.bookService}
        </Button>
        <Button
          onClick={onClose}
          variant="tertiary"
          data-cy="shopping-cart-close-button"
        >
          {translations.buttons.close}
        </Button>
      </div>
    </div>
  )
}
