import { FC } from "react"

import { Loader } from "src/components/common/loader"
import { InquirySummary } from "src/components/core/bookingSummaryCard/sections/inquiry"
import { AddInquiry } from "src/components/core/bookingSummaryCard/sections/inquiry/addInquiry"
import { MostBookedServices } from "src/components/core/mostBookedServices"
import { create } from "src/helpers/bem"

import { useGetContentfulServicesData } from "src/hooks/useGetContentfulServicesData"

import { useServiceFlowData } from "src/states/serviceFlow"

import { FAQSection } from "./faqSection"

import { AppointmentDetails } from "./sections/AppointmentDetails"
import { ServicesList } from "./sections/ServicesList"

import styles from "./ShoppingCartContent.module.scss"

const bem = create(styles, "ShoppingCartContent")

export type ShoppingCartContentProps = {
  onClose?: () => void
  onSelectMostBookedService?: (name: string) => void
}

export const ShoppingCartContent: FC<ShoppingCartContentProps> = ({
  onClose,
  onSelectMostBookedService,
}) => {
  const { inquiry } = useServiceFlowData()
  const { categoriesData, isLoading } = useGetContentfulServicesData()

  return (
    <div className={bem()} data-cy="shopping-cart-content">
      {isLoading ? (
        <Loader size="lg" className={bem("loader")} />
      ) : (
        <>
          <ServicesList onClose={onClose} />

          <AppointmentDetails />

          <AddInquiry />

          <InquirySummary inquiry={inquiry} isShoppingCart />

          <FAQSection />

          <MostBookedServices
            onSelect={onSelectMostBookedService}
            categoriesData={categoriesData}
          />
        </>
      )}
    </div>
  )
}
