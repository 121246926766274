import { mergeAccountData, setAccountData } from "./account"
import { AccountData } from "./types"

const initialState: AccountData = {}

const accountActions = {
  clearAccountData: () => setAccountData(initialState),
  updateAccountData: (data: AccountData) => {
    mergeAccountData(data)
  },
}

export default accountActions
