import { useIncompleteCustomerData } from "src/components/pages/services/checkout/hooks/useIncompleteCustomerData"
import { FlowStep } from "src/config"
import { isPitstopGarage } from "src/helpers/isPitstopGarage"
import { useTranslation } from "src/hooks/translation"
import { useInspectionFlows } from "src/hooks/useInspectionFlows"
import { useIsOnlineAppointmentAvailable } from "src/hooks/useIsOnlineAppointmentAvailable"
import { useServiceFlowData } from "src/states/serviceFlow"

export const useSubmitButtonTitle = (step?: FlowStep) => {
  const { messages } = useTranslation()
  const translations = messages.pages.services.flow.buttons.submit

  const { isPitstopInspectionFlow } = useInspectionFlows()
  const { hasAllCustomerData } = useIncompleteCustomerData()
  const { detailedGarage, timeSlot } = useServiceFlowData()
  const isPitstop = isPitstopGarage(detailedGarage?.garage?.dmsProviderType)
  const isRequest = !isPitstop || isPitstopInspectionFlow

  const isOnlineAppointment = useIsOnlineAppointmentAvailable(
    detailedGarage?.garage,
  )

  function getButtonLabels() {
    const { complete } = translations
    return isRequest ? complete.request : complete.payment
  }

  function getPaymentStepTitle() {
    if (!timeSlot && !isRequest) {
      return translations.complete.selectAppointment
    }

    const paymentStepKey = hasAllCustomerData
      ? "withCustomerData"
      : "withoutCustomerData"

    const buttonLabels = getButtonLabels()

    return buttonLabels[paymentStepKey]
  }

  const stepsTitles: Partial<Record<FlowStep, string>> = {
    fahrzeug: translations.vehicle,
    werkstaetten: translations.garage,
    termin:
      isOnlineAppointment || !detailedGarage?.garage
        ? translations.appointment
        : translations.appointmentRequest,
    "persoenliche-daten": translations.checkout,
    bezahlen: getPaymentStepTitle(),
  }

  if (!step || !stepsTitles[step]) {
    return translations.default
  }

  return stepsTitles[step]
}
