import React, { FC } from "react"

import { Button } from "src/components/common/button"
import { FlowStep, serviceFlowSteps } from "src/config"
import { create } from "src/helpers/bem"

import { useInspectionFlows } from "src/hooks/useInspectionFlows"
import { useServiceFlowData } from "src/states/serviceFlow"

import styles from "./Buttons.module.scss"
import { useShoppingCartButtons } from "./hooks/useShoppingCartButtons"
import { ShoppingCartTotalPrice } from "./ShoppingCartTotalPrice"
import { ShoppingCartButtons } from "./types"

const bem = create(styles, "Buttons")

type ButtonsProps = {
  currentFlowStep?: FlowStep
  shoppingCartButtons?: ShoppingCartButtons
}

export const Buttons: FC<ButtonsProps> = ({
  shoppingCartButtons,
  currentFlowStep,
}) => {
  const { detailedGarage } = useServiceFlowData()
  const totalPrice = detailedGarage?.priceAfterDiscount

  const { isPitstopInspectionFlow } = useInspectionFlows()

  const { forwardButton, backButton } = useShoppingCartButtons(
    shoppingCartButtons,
    currentFlowStep || serviceFlowSteps.service,
  )

  return (
    <div className={bem()}>
      {!!totalPrice && !isPitstopInspectionFlow && (
        <ShoppingCartTotalPrice totalPrice={totalPrice} />
      )}

      {!!forwardButton && (
        <Button
          variant="primary"
          className={bem("button")}
          disabled={forwardButton.disabled}
          onClick={forwardButton.onClick}
          data-cy="shopping-cart-continue-button"
        >
          {forwardButton.label}
        </Button>
      )}

      {!!backButton && (
        <Button
          variant="secondary"
          className={bem("button")}
          disabled={backButton.disabled}
          onClick={backButton.onClick}
          data-cy="shopping-cart-back-button"
        >
          {backButton.label}
        </Button>
      )}
    </div>
  )
}
