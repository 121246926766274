import { FC } from "react"

import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"
import { formatters } from "src/translations/formatters"

import styles from "./ShoppingCartTotalPrice.module.scss"

const bem = create(styles, "ShoppingCartTotalPrice")

type ShoppingCartTotalPriceProps = {
  totalPrice: number
}

export const ShoppingCartTotalPrice: FC<ShoppingCartTotalPriceProps> = ({
  totalPrice,
}) => {
  const { messages } = useTranslation()
  const translations = messages.components.core.shoppingCart.totalPrice

  const currencyFormatter = formatters.currency

  return (
    <div className={bem()}>
      <span className={bem("text")}>{translations.total}</span>
      <span className={bem("price")}>
        {currencyFormatter.format(totalPrice)}
      </span>
      <span className={bem("text")}>{translations.tax}</span>
    </div>
  )
}
