import { ButtonHTMLAttributes } from "react"

import { create } from "src/helpers/bem"

import styles from "./Chips.module.scss"

const bem = create(styles, "Chips")

export type Chip<T = {}> = T &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> & {
    id: string
    title: string
  }

export type ChipsProps<T = {}> = {
  variant: "primary"
  className?: string
  items: Chip<T>[]
  onClick: (item: Chip<T>) => void
}

export const Chips = <T extends unknown>({
  variant,
  className,
  items,
  onClick,
}: ChipsProps<T>) => {
  if (!items.length) {
    return null
  }

  return (
    <div className={bem(undefined, undefined, className)}>
      {items.map((item) => {
        const { id, title, ...attributes } = item
        return (
          <button
            key={id}
            type="button"
            className={bem("item", { [variant]: true })}
            onClick={() => onClick(item)}
            {...attributes}
          >
            {title}
          </button>
        )
      })}
    </div>
  )
}
