import { useServiceFlowData } from "src/states/serviceFlow"

export const useIncompleteCustomerData = () => {
  const { customer } = useServiceFlowData()

  const requiredFields = [
    customer?.title,
    customer?.firstName,
    customer?.lastName,
    customer?.email,
    customer?.street,
    customer?.houseNumber,
    customer?.postalCode,
    customer?.city,
    customer?.country,
    customer?.phoneNumber,
  ]

  const hasAllCustomerData = requiredFields.every(
    (value) => !!value && value.trim() !== "",
  )

  const hasEmail = !!customer?.email

  return {
    hasAllCustomerData,
    hasEmailButIncompleteCustomerData: hasEmail && !hasAllCustomerData,
  }
}
