import { FC, useEffect } from "react"

import { Icon } from "src/components/common/icon"
import { Modal, ModalContent, ModalTrigger } from "src/components/common/modal"

import { FlowStep } from "src/config"
import { create } from "src/helpers/bem"

import { useIsMounted } from "src/hooks/isMounted"
import { useTranslation } from "src/hooks/translation"

import { useServiceFlowData } from "src/states/serviceFlow"

import shoppingCartActions, {
  initializeShoppingCartState,
} from "src/states/shoppingCart/shoppingCartActions"

import { Buttons } from "./buttons"
import { ShoppingCartButtons } from "./buttons/types"
import { EmptyShoppingCart } from "./emptyShoppingCart"
import { useShoppingCartTrackingEvents } from "./hooks/useShoppingCartTrackingEvents"
import styles from "./ShoppingCart.module.scss"
import { ShoppingCartContent } from "./ShoppingCartContent"

const bem = create(styles, "ShoppingCart")

export type ShoppingCartProps = {
  currentFlowStep?: FlowStep
  shoppingCartButtons?: ShoppingCartButtons
}

export const ShoppingCart: FC<ShoppingCartProps> = ({
  currentFlowStep,
  shoppingCartButtons,
}) => {
  const { messages, formatMessage } = useTranslation()
  const translations = messages.components.core.shoppingCart

  const { serviceCategories, inquiry } = useServiceFlowData()
  const itemsInCart = serviceCategories?.length || 0
  const isShoppingCartEmpty = !itemsInCart && !inquiry?.message

  const { trackOpenCloseShoppingCart, trackMostBookedServiceClick } =
    useShoppingCartTrackingEvents()

  initializeShoppingCartState()

  const isCartOpen = shoppingCartActions.isOpen()

  const onOpenChangeShoppingCart = (open: boolean, track: boolean = true) => {
    shoppingCartActions.set(open)

    if (track) {
      trackOpenCloseShoppingCart(open)
    }
  }

  const onSelectMostBookedService = (name: string) => {
    trackMostBookedServiceClick(name)
    onOpenChangeShoppingCart(false, false)
  }

  useEffect(() => {
    return () => {
      // Ensures the cart state is set to closed when the component is destroyed
      shoppingCartActions.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isMounted = useIsMounted()

  if (!isMounted) return null

  return (
    <Modal open={isCartOpen} onOpenChange={onOpenChangeShoppingCart}>
      <ModalTrigger>
        <button
          className={bem("icon")}
          data-cy="shopping-cart-button"
          aria-label={formatMessage(translations.button.label, { itemsInCart })}
        >
          <Icon name="shoppingCart" aria-hidden="true" />
          {!!itemsInCart && (
            <span className={bem("count")} data-cy="shopping-cart-count">
              {itemsInCart}
            </span>
          )}
        </button>
      </ModalTrigger>

      {isCartOpen && (
        <ModalContent
          variant="menu"
          bodyClassName={bem("modal-body")}
          title={translations.title}
          footer={
            !isShoppingCartEmpty && (
              <Buttons
                shoppingCartButtons={shoppingCartButtons}
                currentFlowStep={currentFlowStep}
              />
            )
          }
        >
          <div className={bem("content")}>
            {isShoppingCartEmpty ? (
              <EmptyShoppingCart
                onClose={() => onOpenChangeShoppingCart(false, true)}
              />
            ) : (
              <>
                <ShoppingCartContent
                  onClose={() => onOpenChangeShoppingCart(false, false)}
                  onSelectMostBookedService={onSelectMostBookedService}
                />
              </>
            )}
          </div>
        </ModalContent>
      )}
    </Modal>
  )
}
