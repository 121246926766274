import { FC } from "react"

import { AppointmentSection } from "../../bookingSummaryCard/sections/appointmentSection"
import { useTranslation } from "src/hooks/translation"
import { useServiceFlowData } from "src/states/serviceFlow"

export const AppointmentDetails: FC = () => {
  const { messages } = useTranslation()
  const translations = messages.components.core.shoppingCart.appointment
  const { timeSlot, detailedGarage, vehicle } = useServiceFlowData()

  if (!timeSlot && !detailedGarage && !vehicle) {
    return
  }

  const vehicleDisplayName =
    vehicle?.vehicleDisplayName || vehicle?.displayName || ""

  const car = {
    make: translations.vehicle,
    model: vehicleDisplayName,
    licensePlate: vehicle?.licensePlate,
    hsn: vehicle?.hsn,
    tsn: vehicle?.tsn,
  }
  return (
    <AppointmentSection
      timeslot={timeSlot ? new Date(timeSlot) : null}
      garage={detailedGarage?.garage}
      car={car}
    />
  )
}
