import Router, { useRouter } from "next/router"

import { useShoppingCartTrackingEvents } from "../../hooks/useShoppingCartTrackingEvents"
import { ShoppingCartButtons } from "../types"
import { useBackButtonTitle } from "src/components/layouts/flow/hooks/useBackButtonTitle"
import { useFlowStep } from "src/components/layouts/flow/hooks/useFlowStep"
import { useSubmitButtonTitle } from "src/components/layouts/flow/hooks/useSubmitButtonTitle"
import { FlowStep, ROUTES, serviceFlowSteps } from "src/config"
import { useTranslation } from "src/hooks/translation"
import { useFlow } from "src/hooks/useFlow"

export const useShoppingCartButtons = (
  shoppingCartButtons?: ShoppingCartButtons,
  currentFlowStep?: FlowStep,
) => {
  const { messages } = useTranslation()
  const translations = messages.components.core.shoppingCart.navigation

  const { flow } = useFlow()
  const { nextFlowStep, prevFlowStep } = useFlowStep(currentFlowStep)
  const cartButtonTitle = useSubmitButtonTitle(nextFlowStep)
  const backButtonTitle = useBackButtonTitle(prevFlowStep)
  const router = useRouter()

  const { trackContinueToNextStepClick, trackBackButtonClick } =
    useShoppingCartTrackingEvents()

  function getShoppingCartForwardButtonLabel() {
    return (
      shoppingCartButtons?.forward?.label ||
      cartButtonTitle ||
      translations.buttons.forward
    )
  }

  const forwardButton: ShoppingCartButtons["forward"] = !shoppingCartButtons
    ?.forward?.hideButton
    ? {
        label: getShoppingCartForwardButtonLabel(),
        disabled: !!shoppingCartButtons?.forward?.disabled,
        dataCy: shoppingCartButtons?.forward?.dataCy,
        onClick: () => {
          trackContinueToNextStepClick()

          if (shoppingCartButtons?.forward?.onClick) {
            return shoppingCartButtons.forward.onClick()
          }

          Router.push(ROUTES.serviceFlow({ flowStep: nextFlowStep, flow }))
        },
      }
    : undefined

  const hasNoPrevStep =
    router.asPath === ROUTES.serviceFlow({ flowStep: serviceFlowSteps.service })

  const backButton: ShoppingCartButtons["back"] =
    !shoppingCartButtons?.back?.hideButton && !hasNoPrevStep
      ? {
          label: shoppingCartButtons?.back?.label || backButtonTitle,
          disabled: !!shoppingCartButtons?.back?.disabled,
          dataCy: shoppingCartButtons?.back?.dataCy,
          onClick: () => {
            trackBackButtonClick()

            if (shoppingCartButtons?.back?.onClick) {
              return shoppingCartButtons.back.onClick()
            }

            if (prevFlowStep) {
              return Router.push(
                ROUTES.serviceFlow({ flowStep: prevFlowStep, flow }),
              )
            }

            Router.back()
          },
        }
      : undefined

  return { forwardButton, backButton }
}
